// components/StudentProjects/StudentProject.js
import React from "react";
import styled from "styled-components";

const StudentProjectCard = styled.div`
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    max-width: 400px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const StudentProjectTitle = styled.h3`
    font-size: 18px;
    color: #333;
`;

const StudentProjectDetails = styled.div`
    font-size: 16px;
    color: #555;
    margin: 10px 0;
`;

const PartnerText = styled.div`
    font-size: 14px;
    color: #888;
    margin: 5px 0;
    font-style: italic;
`;

const TeamSizeText = styled.div`
    font-size: 16px;
    color: #666;
    margin: 10px 0;
`;

const ApplyButton = styled.a`
    display: inline-block;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #0070f3;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;

    &:hover {
        background-color: #005bb5;
    }
`;

function StudentProject({ title, description, keywords, contact, moreInfo, partner, teamSize }) {
    return (
        <StudentProjectCard>
            <StudentProjectTitle>{title}</StudentProjectTitle>
            <StudentProjectDetails>{description}</StudentProjectDetails>
            <StudentProjectDetails>
                <strong>Keywords:</strong> {keywords.join(", ")}
            </StudentProjectDetails>
            <StudentProjectDetails>
                <strong>Contact:</strong> {contact}
            </StudentProjectDetails>
            {partner && <PartnerText>Partner: {partner}</PartnerText>}
            <TeamSizeText>Student Team Size: {teamSize}/3 </TeamSizeText>
            {moreInfo && (
                <ApplyButton href={moreInfo} target="_blank" rel="noopener noreferrer">
                    Learn More
                </ApplyButton>
            )}
        </StudentProjectCard>
    );
}

export default StudentProject;
